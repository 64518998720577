import React, { useContext } from "react";
import {
  compareAge,
  compareTeam,
  compareDebut,
  compareHeight,
  compareNumber,
  comparePos,
  compareState,
} from "../../lib/utils/compare";
import { MAX_GUESSES } from "../../lib/utils/constants";
import { getAge } from "../../lib/utils/common";
import teamMap from "../../lib/utils/teamMap";
import teamLogoMap from "../../lib/utils/teamLogoMap";
import posMap from "../../lib/utils/posMap";
import { DataContext } from "../../lib/store/DataProviders";
import { GuessCell } from "../../components/guess/GuessCell";

export function GuessRow({ player, scrollRef, attempt }) {
  const stores = useContext(DataContext);
  const target = stores.useGameStore((state) => state.target);
  const isCorrect = target.playerId === player.playerId;
  const isOutOfGuesses = attempt === MAX_GUESSES + 1;
  const isComplete = isCorrect || isOutOfGuesses;

  const colors = isOutOfGuesses
    ? "bg-gradient-to-t from-[#edeae5] to-[#920000]"
    : isCorrect
    ? "bg-gradient-to-t from-[#edeae5] to-[#37be75]"
    : "bg-gradient-to-t from-[#edeae5] to-white";

  return (
    <div
      ref={scrollRef}
      className={`game-table__row grid animate-fade-in grid-cols-[60px_repeat(6,_minmax(0,_1fr))] px-2.5 md:grid-cols-[250px_100px_repeat(6,_minmax(0,_1fr))] md:p-0 ${colors} border-b border-gray-500 last:border-0`}
    >
      <div
        className={`game-table__cell bg-gradient-non md:bg-solid flex items-center justify-start border-r border-none border-[#f5f2ec] py-4 text-lg font-bold leading-none md:border-solid md:px-2.5 ${
          isComplete && "text-white md:bg-[#37be75]"
        } ${isOutOfGuesses && "text-white md:bg-[#920000]"}`}
      >
        {player.givenName} {player.surname}
      </div>

      <GuessCell
        value={player.team}
        status={compareTeam(target, player)}
        logoId={teamLogoMap[player.team]}
        failed={isOutOfGuesses}
      />
      <GuessCell
        value={teamMap[player.team].state}
        status={compareState(target, player)}
        failed={isOutOfGuesses}
      />
      <GuessCell
        value={posMap[player.position].label}
        status={comparePos(target, player)}
        failed={isOutOfGuesses}
      />
      <GuessCell
        value={player.debutYear}
        status={compareDebut(target, player)}
        failed={isOutOfGuesses}
      />
      <GuessCell
        value={player.heightCm}
        status={compareHeight(target, player)}
        suffix="cm"
        failed={isOutOfGuesses}
      />
      <GuessCell
        value={getAge(player.dateOfBirth)}
        status={compareAge(target, player)}
        failed={isOutOfGuesses}
      />
      <GuessCell
        value={player.jumperNumber}
        status={compareNumber(target, player)}
        failed={isOutOfGuesses}
      />
    </div>
  );
}
