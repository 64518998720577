import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

function SilhouetteModal(props) {
  return (
    <>
      <Transition appear show={props.show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={props.onClose}
        >
          <div className="min-h-screen px-2.5 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-[calc(var(--vh,1vh)*100)] align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500 delay-100"
              enterFrom="opacity-0 scale-100 mb-20"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100 mb-20"
            >
              <div className="inline-block w-full max-w-lg p-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold text-center leading-6 text-worpel-blue"
                >
                  <ModalCloseButton clickCallback={props.onClose} />
                </Dialog.Title>
                {props.children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="float-right inline-flex justify-center -mt-2 px-2 py-2 text-3xl font-medium leading-none text-neutral-900 opacity-60 hover:opacity-100"
      onClick={clickCallback}
    >
      ×
    </button>
  );
}

export default SilhouetteModal;
