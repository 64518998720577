import { useContext } from "react";
import { DataContext } from "../../lib/store/DataProviders";

function SilhouetteImage({ reveal = false }) {
  const stores = useContext(DataContext);
  const imgUrl = stores.useGameStore((state) => state.imgUrl);

  return (
    imgUrl !== null && (
      <div
        className={`inner bg-contain bg-no-repeat ${
          !reveal && "brightness-0"
        } bg-center h-[240px] w-64 mx-auto -mt-3.5`}
        style={{ backgroundImage: `url("${imgUrl}")` }}
      ></div>
    )
  );
}

export default SilhouetteImage;
