import {
  compareAge,
  compareTeam,
  compareDebut,
  compareHeight,
  compareNumber,
  comparePos,
  compareState,
} from "../../../lib/utils/compare";

export function worpelGuessesToResults(target, guesses, players) {
  const result = guesses.map((guess) => {
    const player = players[guess.playerId];

    return [
      target === player ? 2 : 0,
      compareTeam(target, player)[0],
      compareState(target, player)[0],
      comparePos(target, player)[0],
      compareDebut(target, player)[0],
      compareHeight(target, player)[0],
      compareAge(target, player)[0],
      compareNumber(target, player)[0],
    ];
  });

  return result;
}
