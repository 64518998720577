export function trackShare({ method, itemId }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "share", {
      method: method,
      content_type: "text",
      item_id: itemId,
    });
  }
}

export function trackSelect({ contentType, itemId }) {
  // window.gtag("event", "select_content", {
  //   content_type: contentType,
  //   item_id: itemId,
  // });
}

export function trackLevelStart({ gameName, levelNumber, target }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "level_start", {
      level_name: `${gameName} ${levelNumber}: ${target.givenName} ${target.surname}`,
    });
  }
}

export function trackLevelEnd({ gameName, levelNumber, target, success }) {
  if (typeof window !== "undefined") {
    window.gtag("event", "level_end", {
      level_name: `${gameName} ${levelNumber}: ${target.givenName} ${target.surname}`,
      success: success,
    });
  }
}
