const posMap = {
  KEY_DEFENDER: {
    label: "Key Def",
    close: ["MEDIUM_DEFENDER"],
  },
  MEDIUM_DEFENDER: {
    label: "Med Def",
    close: ["KEY_DEFENDER"],
  },
  RUCK: {
    label: "Ruck",
    close: [],
  },
  MIDFIELDER: {
    label: "Mid",
    close: ["MIDFIELDER_FORWARD"],
  },
  MIDFIELDER_FORWARD: {
    label: "Mid-Fwd",
    close: ["MIDFIELDER", "MEDIUM_FORWARD", "KEY_FORWARD"],
  },
  MEDIUM_FORWARD: {
    label: "Med Fwd",
    close: ["MIDFIELDER_FORWARD", "KEY_FORWARD"],
  },
  KEY_FORWARD: {
    label: "Key Fwd",
    close: ["MIDFIELDER_FORWARD", "MEDIUM_FORWARD"],
  },
};

export default posMap;
