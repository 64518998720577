import { isMobile } from "react-device-detect";
import { legendsGuessesToResults } from "../../games/Legends/utils/guessesToResults";
import { worpelGuessesToResults } from "../../games/Worpel/utils/guessesToResults";
import { enums } from "../utils/constants";
import { trackShare } from "./analytics";

export async function handleShare({ stores }) {
  const guesses = stores.usePersistentStore.getState().guesses;
  const silhouetteOn = stores.usePersistentStore.getState().silhouetteOn;
  const target = stores.useGameStore.getState().target;
  const levelNumber = stores.useGameStore.getState().levelNumber;
  const levelResult = stores.useGameStore.getState().levelResult;
  const gameName = stores.useGameStore.getState().shareGameName;
  const gameUrl = stores.useGameStore.getState().shareGameUrl;
  const players = stores.useGameStore.getState().players;
  //  const guessesToResults = stores.useGameStore.getState().guessesToResults;
  const guessesToResults =
    stores.useGameStore.getState().game === enums.game.LEGENDS
      ? legendsGuessesToResults
      : worpelGuessesToResults;

  const guessStats = guessesToResults(target, guesses, players);
  const copy = shareCopy(
    levelNumber,
    levelResult,
    guessStats,
    silhouetteOn,
    gameName,
    gameUrl
  );

  share({ copy, levelNumber });
}

export function shareCopy(
  levelNumber,
  levelResult,
  guessStats,
  silhouetteOn,
  gameName,
  gameUrl
) {
  const silhouetteOnText =
    silhouetteOn === 0 ? "(No 👤)" : `(👤 on ${silhouetteOn})`;
  const attempts =
    levelResult === enums.levelResult.LOST ? "X" : guessStats.length;
  const emojiText = emojiGrid(guessStats);

  const copy = `${gameName} ${levelNumber} - ${attempts}/8 ${silhouetteOnText}

${emojiText}
${gameUrl}`;

  console.log(copy);

  return copy;
}

export async function share({ copy, levelNumber }) {
  if ("share" in navigator && isMobile) {
    const shareData = {
      text: copy,
    };

    try {
      await navigator.share(shareData);
      trackShare({ method: "Mobile", itemId: levelNumber });
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      updateClipboard(copy);
      trackShare({ method: "Copy", itemId: levelNumber });
    } catch (err) {
      console.log(err);
    }
  }
}

function emojiGrid(a) {
  let grid = "";
  a.forEach((row) => {
    row.forEach((result) => {
      if (result === 0) {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          grid += "⬛";
        } else {
          grid += "⬜";
        }
      } else if (result === 1) {
        grid += "🟨";
      } else {
        grid += "🟩";
      }
    });
    grid += "\n";
  });

  return grid;
}

function updateClipboard(newClip) {
  return navigator.clipboard.writeText(newClip);
}
