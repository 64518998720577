import create from "zustand";
import { persist } from "zustand/middleware";
import { worpelLevelNumber } from "../utils/worpelLevels";

const useWorpelStore = create(
  persist(
    (set, get) => ({
      lastPlayed: null,
      saveLastPlayed: (number) =>
        set(() => ({
          lastPlayed: number,
        })),

      silhouetteOn: 0,
      saveSilhouetteOn: (attempt) =>
        set(() => ({
          silhouetteOn: attempt,
          lastPlayed: worpelLevelNumber,
        })),
      resetSilhouetteOn: () =>
        set(() => ({
          silhouetteOn: 0,
        })),

      guesses: [],
      saveGuess: (player) =>
        set((prevState) => ({
          guesses: [...prevState.guesses, player],
          lastPlayed: worpelLevelNumber,
        })),
      resetGuesses: () =>
        set(() => ({
          guesses: [],
        })),

      history: {},
      saveHistory: (result) => {
        return set((prevState) => ({
          history: Object.assign(prevState.history, {
            [worpelLevelNumber]: result,
          }),
        }));
      },

      instructionsDismissed: false,
      saveInstructionsDismissed: (isDismissed) =>
        set(() => ({
          instructionsDismissed: isDismissed,
        })),
    }),
    {
      name: "worpel",
      getStorage: () => localStorage,
      merge: (persistedState, currentState) => {
        if (persistedState.lastPlayed !== worpelLevelNumber) {
          console.log(
            `level change ${persistedState.lastPlayed} -> ${worpelLevelNumber}, clearing guesses and silhouetteOn`
          );
          persistedState.guesses = [];
          persistedState.silhouetteOn = 0;
        }

        return { ...currentState, ...persistedState };
      },
    }
  )
);

export default useWorpelStore;
