const startDay = new Date("Sep 13 2022");
const diffTime = Math.abs(new Date() - startDay);
export const legendsLevelNumber = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

export const legendsTargetIds = {
  1: "210002",
  2: "220105",
  3: "220096",
  4: "220063",
  5: "220058",
  6: "230197",
  7: "240052",
  8: "260288",
  9: "990059",
  10: "240546",
  11: "261290",
  12: "210043",
  13: "220062",
  14: "960197",
  15: "240027",
  16: "250118",
  17: "240283",
  18: "290641",
  19: "230041",
  20: "250338",
  21: "281280",
  22: "260257",
  23: "230214",
  24: "230263",
  25: "980007",
  26: "220036",
  27: "271439",
  28: "200023",
  29: "220073",
  30: "200112",
  31: "210001",
  32: "261299",
  33: "980059",
  34: "200035",
  35: "200032",
  36: "230048",
  37: "220089",
  38: "250362",
  39: "210020",
  40: "250063",
  41: "210013",
  42: "240399",
  43: "230147",
  44: "240552",
  45: "250688",
  46: "200002",
  47: "250290",
  48: "240124",
  49: "260227",
  50: "290156",
  51: "210048",
  52: "270919",
  53: "210027",
  54: "990028",
  55: "220093",
  56: "291201",
  57: "261892",
  58: "240707",
  59: "240180",
  60: "200045",
  61: "210056",
  62: "261142",
  63: "261909",
  64: "220015",
  65: "240366",
  66: "240156",
  67: "280109",
  68: "230084",
  69: "261214",
  70: "240073",
  71: "230125",
  72: "200060",
  73: "230272",
  74: "240700",
  75: "220061",
  76: "220110",
  77: "230250",
  78: "230248",
  79: "200074",
  80: "230231",
  81: "280416",
  82: "270811",
  83: "240062",
  84: "290198",
  85: "260246",
  86: "990020",
  87: "281139",
  88: "220023",
  89: "250373",
  90: "990011",
  91: "220098",
  92: "230195",
  93: "220054",
  94: "290787",
  95: "200034",
  96: "261362",
  97: "270588",
  98: "220090",
  99: "280471",
  100: "240072",
  101: "250712",
  102: "240389",
  103: "250298",
  104: "240302",
  105: "270928",
  106: "250417",
  107: "270512",
  108: "220052",
  109: "240232",
  110: "200071",
  111: "200039",
  112: "261043",
  113: "260113",
  114: "240226",
  115: "220056",
  116: "240014",
  117: "240710",
  118: "250222",
  119: "270653",
  120: "270326",
  121: "271078",
  122: "220007",
  123: "210049",
  124: "240357",
  125: "210016",
  126: "240708",
  127: "220047",
  128: "210003",
  129: "250715",
  130: "280934",
  131: "240712",
  132: "260069",
  133: "261212",
  134: "240370",
  135: "230107",
  136: "230132",
  137: "290194",
  138: "240254",
  139: "230170",
  140: "220005",
  141: "280762",
  142: "261911",
  143: "240290",
  144: "240359",
  145: "220086",
  146: "230211",
  147: "250111",
  148: "220075",
  149: "280013",
  150: "281281",
  151: "230242",
  152: "260710",
  153: "250312",
  154: "200021",
  155: "200007",
  156: "230202",
  157: "230140",
  158: "270912",
  159: "230151",
  160: "250268",
  161: "240159",
  162: "280858",
  163: "220108",
  164: "220013",
  165: "200111",
  166: "200083",
  167: "240119",
  168: "270732",
  169: "210012",
  170: "240139",
  171: "250703",
  172: "280336",
  173: "240060",
  174: "210059",
  175: "220001",
  176: "270908",
  177: "280972",
  178: "200108",
  179: "240087",
  180: "230253",
  181: "960491",
  182: "230254",
  183: "261799",
  184: "260310",
  185: "271001",
  186: "200026",
  187: "260955",
  188: "230232",
  189: "250089",
  190: "980073",
  191: "230243",
  192: "220010",
  193: "210023",
  194: "250134",
  195: "240028",
  196: "210092",
  197: "270951",
  198: "240037",
  199: "261497",
  200: "281395",
  201: "240417",
  202: "200068",
  203: "230015",
  204: "220039",
  205: "280969",
  206: "230255",
  207: "240419",
  208: "210018",
  209: "280863",
  210: "250340",
  211: "250548",
  212: "240130",
  213: "240336",
  214: "210099",
  215: "270742",
  216: "200079",
  217: "260750",
  218: "295467",
  219: "271015",
  220: "291492",
  221: "290117",
  222: "294877",
  223: "293535",
  224: "281080",
  225: "291545",
  226: "270935",
  227: "280804",
  228: "290826",
  229: "290801",
  230: "295584",
  231: "290188",
  232: "291783",
  233: "270896",
  234: "992016",
  235: "270963",
  236: "290832",
  237: "290847",
  238: "281373",
  239: "295265",
  240: "293222",
  241: "280104",
  242: "291548",
  243: "290778",
  244: "291748",
  245: "293884",
  246: "291867",
  247: "271129",
  248: "291526",
  249: "280763",
  250: "291771",
  251: "280317",
  252: "290311",
  253: "261209",
  254: "293193",
  255: "294318",
  256: "291533",
  257: "291570",
  258: "230197",
  259: "280990",
  260: "261396",
  261: "960197",
  262: "260930",
  263: "291773",
  264: "261323",
  265: "250118",
  266: "290641",
  267: "230214",
  268: "220096",
  269: "220105",
  270: "250105",
  271: "230048",
  272: "220058",
  273: "250362",
  274: "220036",
  275: "261299",
  276: "240546",
  277: "210013",
  278: "230263",
  279: "230041",
  280: "210043",
  281: "280078",
  282: "240052",
  283: "261384",
  284: "280965",
  285: "200112",
  286: "220073",
  287: "210020",
  288: "290823",
  289: "281007",
  290: "250338",
  291: "280819",
  292: "271439",
  293: "261290",
  294: "260257",
  295: "260382",
  296: "280737",
  297: "220089",
  298: "270861",
  299: "250321",
  300: "250063",
  301: "280944",
  302: "250267",
  303: "210001",
  304: "240406",
  305: "280824",
  306: "240027",
  307: "980059",
  308: "281280",
  309: "200023",
  310: "220062",
  311: "270325",
  312: "290623",
  313: "200032",
  314: "990059",
  315: "260288",
  316: "240283",
  317: "280012",
  318: "210002",
  319: "200035",
  320: "220063",
  321: "270938",
  322: "980007",
  323: "220093",
  324: "250365",
  325: "210027",
  326: "271072",
  327: "250290",
  328: "291313",
  329: "290527",
  330: "261892",
  331: "230231",
  332: "230248",
  333: "240156",
  334: "290799",
  335: "281085",
  336: "220015",
  337: "200060",
  338: "280744",
  339: "281065",
  340: "280711",
  341: "291357",
  342: "210056",
  343: "210048",
  344: "220110",
  345: "290528",
  346: "200074",
  347: "250688",
  348: "240552",
  349: "270146",
  350: "280921",
  351: "292511",
  352: "240073",
  353: "230084",
  354: "240124",
  355: "240399",
  356: "290156",
  357: "261909",
  358: "270919",
  359: "291201",
  360: "292145",
  361: "291351",
  362: "290550",
  363: "260227",
  364: "240707",
  365: "290629",
  366: "261142",
  367: "240700",
  368: "240366",
  369: "990028",
  370: "290160",
  371: "200002",
  372: "271045",
  373: "230147",
  374: "220061",
  375: "261510",
  376: "230250",
  377: "240180",
  378: "270917",
};
