export const PRECISION = 3;
export const MAX_GUESSES = 8;

export const arrowDir = {
  NONE: "",
  UP: "↑",
  DOWN: "↓",
  LEFT: "←",
  RIGHT: "→",
};

export const guessStatus = {
  INCORRECT: 0,
  CLOSE: 1,
  CORRECT: 2,
};

export const enums = {
  levelResult: {
    TBD: 1,
    WON: 2,
    LOST: 3,
  },
  game: {
    WORPEL: "worpel",
    LEGENDS: "legends",
  },
};
