import {
  compareTeam,
  compareHeight,
  compareNumber,
  comparePos,
  compareNumTeams,
  compareLastSeason,
  comparePremierships,
} from "../../../lib/utils/compare";

export function legendsGuessesToResults(target, guesses, players) {
  const result = guesses.map((guess) => {
    const player = players[guess.playerId];

    return [
      target === player ? 2 : 0,
      compareTeam(target, player)[0],
      comparePos(target, player)[0],
      compareNumTeams(target, player)[0],
      compareLastSeason(target, player)[0],
      compareHeight(target, player)[0],
      comparePremierships(target, player)[0],
      compareNumber(target, player)[0],
    ];
  });

  return result;
}
