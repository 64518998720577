import React from "react";
import useGameStore from "./GameStore";
import useLegendsStore from "./LegendsStore";
import useWorpelStore from "./WorpelStore";

export const DataContext = React.createContext();

export function LegendsDataProvider({ children }) {
  const dataStores = {
    useGameStore: useGameStore,
    usePersistentStore: useLegendsStore,
  };

  return (
    <DataContext.Provider value={dataStores}>{children}</DataContext.Provider>
  );
}

export function WorpelDataProvider({ children }) {
  const dataStores = {
    useGameStore: useGameStore,
    usePersistentStore: useWorpelStore,
  };

  return (
    <DataContext.Provider value={dataStores}>{children}</DataContext.Provider>
  );
}
