import { isMobile } from "react-device-detect";
import Image from "next/image";
import banner from "../../public/img/gridley-banner.png";

export function Footer() {
  return (
    <footer className="absolute bottom-2 left-0 right-0 text-center">
      <a
        href="https://gridleygame.com/?utm_source=worpel&utm_medium=display&utm_campaign=launch"
        target="_blank"
      >
        <Image
          src={banner}
          alt="Team logo"
          layout="intrinsic"
          width={600}
          height={163}
        />
      </a>
    </footer>
  );
}
