import { useContext } from "react";
import { trackSelect } from "../../lib/utils/analytics";
import ShareButton from "../ShareButton";
import SilhouetteImage from "../silhouette/SilhouetteImage";
import SilhouetteModal from "../silhouette/SilhouetteModal";
import { DataContext } from "../../lib/store/DataProviders";
import { PlayLegendsFooter } from "./footers/PlayLegendsFooter";
import { StreakFooter } from "./footers/StreakFooter";

function AnswerButton(props) {
  const stores = useContext(DataContext);
  const [target, levelNumber, isOpen, setIsOpen] = stores.useGameStore(
    (state) => [
      state.target,
      state.levelNumber,
      state.isAnswerOpen,
      state.setIsAnswerOpen,
    ]
  );
  const guesses = stores.usePersistentStore(
    (state) => state.history[levelNumber]
  );

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackSelect({
      contentType: "button",
      itemId: "show_answer",
    });
  }

  const guessesLabel = guesses === 1 ? "1 guess" : `${guesses} guesses`;

  return (
    <>
      <button
        onClick={openModal}
        className="uppercase px-5 mt-1 mb-3.5 py-2 md:px-7 md:py-5 text-worpel-blue underline font-bold"
      >
        Show Answer
      </button>

      <SilhouetteModal show={isOpen} onClose={closeModal}>
        <ModalBody
          target={target}
          guessesLabel={guessesLabel}
          footer={props.footer}
        />
      </SilhouetteModal>
    </>
  );
}

function ModalBody({ target, guessesLabel, footer }) {
  return (
    <div className="mt-2 text-center text-worpel-blue">
      <SilhouetteImage reveal={true} />
      <div className="border-y border-solid border-worpel-blue pt-7 md:pt-9 pb-6 md:pb-8 px-2.5">
        <h3 className="md:text-xl">Great job!</h3>
        <h2 className="text-xl md:text-3xl font-extrabold uppercase my-2.5 tracking-wide leading-tight">
          {`${target.givenName} ${target.surname}`}
        </h2>
        <h3 className="mb-5 md:mb-7 md:text-xl">
          You solved it in {guessesLabel}
        </h3>
        <ShareButton />
      </div>
      {footer || <StreakFooter />}
    </div>
  );
}

export default AnswerButton;
