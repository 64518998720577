// const startDay = new Date("Mar 21 2022");
const startDay = new Date("Apr 4 2022");
const diffTime = Math.abs(new Date() - startDay);
// export const worpelLevelNumber = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
export const worpelLevelNumber =
  Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 14;

export const worpelTargetIds = {
  1: "CD_I994185",
  2: "CD_I1000978",
  3: "CD_I280990",
  4: "CD_I993794",
  5: "CD_I290757",
  6: "CD_I240399",
  7: "CD_I293581",
  8: "CD_I994385",
  9: "CD_I1000860",
  10: "CD_I291776",
  11: "CD_I1002240",
  12: "CD_I998529",
  13: "CD_I1008089",
  14: "CD_I280078",
  15: "CD_I993107",
  16: "CD_I291771",
  17: "CD_I295446",
  18: "CD_I1001017",
  19: "CD_I293883",
  20: "CD_I993817",
  21: "CD_I296294",
  22: "CD_I295136",
  23: "CD_I298336",
  24: "CD_I1000972",
  25: "CD_I1001024",
  26: "CD_I298264",
  27: "CD_I295342",
  28: "CD_I993993",
  29: "CD_I297401",
  30: "CD_I993832",
  31: "CD_I1003130",
  32: "CD_I1004938",
  33: "CD_I291550",
  34: "CD_I296225",
  35: "CD_I1000072",
  36: "CD_I1009528",
  37: "CD_I294596",
  38: "CD_I1011640",
  39: "CD_I298524",
  40: "CD_I297504",
  41: "CD_I296420",
  42: "CD_I294613",
  43: "CD_I1015873",
  44: "CD_I294305",
  45: "CD_I1010174",
  46: "CD_I997230",
  47: "CD_I999321",
  48: "CD_I294859",
  49: "CD_I1012825",
  50: "CD_I1017051",
  51: "CD_I280744",
  52: "CD_I993998",
  53: "CD_I291545",
  54: "CD_I280965",
  55: "CD_I1006126",
  56: "CD_I992242",
  57: "CD_I280317",
  58: "CD_I1009256",
  59: "CD_I996232",
  60: "CD_I998172",
  61: "CD_I1002239",
  62: "CD_I294674",
  63: "CD_I993480",
  64: "CD_I290799",
  65: "CD_I281124",
  66: "CD_I992010",
  67: "CD_I290826",
  68: "CD_I998390",
  69: "CD_I996765",
  70: "CD_I990740",
  71: "CD_I1008185",
  72: "CD_I1009208",
  73: "CD_I296291",
  74: "CD_I298279",
  75: "CD_I1004592",
  76: "CD_I281373",
  77: "CD_I298280",
  78: "CD_I293801",
  79: "CD_I1009420",
  80: "CD_I991930",
  81: "CD_I991988",
  82: "CD_I271129",
  83: "CD_I1017059",
  84: "CD_I290188",
  85: "CD_I1008541",
  86: "CD_I1006094",
  87: "CD_I992644",
  88: "CD_I1000887",
  89: "CD_I1011803",
  90: "CD_I291748",
  91: "CD_I1002401",
  92: "CD_I1002227",
  93: "CD_I290627",
  94: "CD_I298111",
  95: "CD_I295461",
  96: "CD_I240052",
  97: "CD_I1001195",
  98: "CD_I298288",
  99: "CD_I295898",
  100: "CD_I1001299",
  101: "CD_I290199",
  102: "CD_I290629",
  103: "CD_I271072",
  104: "CD_I1001028",
  105: "CD_I999331",
  106: "CD_I270325",
  107: "CD_I1000937",
  108: "CD_I290832",
  109: "CD_I992374",
  110: "CD_I1012014",
  111: "CD_I1009301",
  112: "CD_I295265",
  113: "CD_I297907",
  114: "CD_I280988",
  115: "CD_I250321",
  116: "CD_I998647",
  117: "CD_I1011985",
  118: "CD_I292145",
  119: "CD_I294557",
  120: "CD_I295340",
  121: "CD_I990290",
  122: "CD_I1008154",
  123: "CD_I1005577",
  124: "CD_I290311",
  125: "CD_I1020895",
  126: "CD_I1012807",
  127: "CD_I296324",
  128: "CD_I294266",
  129: "CD_I993799",
  130: "CD_I998484",
  131: "CD_I994295",
  132: "CD_I996554",
  133: "CD_I996701",
  134: "CD_I996731",
  135: "CD_I997933",
  136: "CD_I1009308",
  137: "CD_I1012857",
  138: "CD_I291357",
  139: "CD_I294101",
  140: "CD_I993828",
  141: "CD_I293846",
  142: "CD_I297452",
  143: "CD_I1013128",
  144: "CD_I261323",
  145: "CD_I1005247",
  146: "CD_I1005199",
  147: "CD_I1002143",
  148: "CD_I1009199",
  149: "CD_I992128",
  150: "CD_I1006550",
  151: "CD_I280824",
  152: "CD_I298290",
  153: "CD_I993771",
  154: "CD_I1009229",
  155: "CD_I295067",
  156: "CD_I297373",
  157: "CD_I990291",
  158: "CD_I296269",
  159: "CD_I298630",
  160: "CD_I290778",
  161: "CD_I296422",
  162: "CD_I280109",
  163: "CD_I297566",
  164: "CD_I1008384",
  165: "CD_I280921",
  166: "CD_I294643",
  167: "CD_I261396",
  168: "CD_I1002282",
  169: "CD_I298268",
  170: "CD_I997316",
  171: "CD_I1009551",
  172: "CD_I290550",
  173: "CD_I1009410",
  174: "CD_I1017126",
  175: "CD_I992016",
  176: "CD_I1005521",
  177: "CD_I1023261",
  178: "CD_I1006028",
  179: "CD_I1015507",
  180: "CD_I293222",
  181: "CD_I1002251",
  182: "CD_I1006314",
  183: "CD_I294508",
  184: "CD_I997078",
  185: "CD_I1006144",
  186: "CD_I1008893",
  187: "CD_I1013462",
  188: "CD_I294036",
  189: "CD_I997823",
  190: "CD_I261510",
  191: "CD_I991933",
  192: "CD_I1006152",
  193: "CD_I290797",
  194: "CD_I294518",
  195: "CD_I298421",
  196: "CD_I997974",
  197: "CD_I1006232",
  198: "CD_I1018075",
  199: "CD_I296254",
  200: "CD_I290314",
  201: "CD_I293713",
  202: "CD_I296190",
  203: "CD_I999715",
  204: "CD_I298210",
  205: "CD_I1009380",
  206: "CD_I1004880",
  207: "CD_I291783",
  208: "CD_I1008123",
  209: "CD_I1008288",
  210: "CD_I994389",
  211: "CD_I293716",
  212: "CD_I1006143",
  213: "CD_I294318",
  214: "CD_I999309",
  215: "CD_I290838",
  216: "CD_I291533",
  217: "CD_I298289",
  218: "CD_I294307",
  219: "CD_I1001398",
  220: "CD_I1008550",
  221: "CD_I998129",
  222: "CD_I1004998",
  223: "CD_I1005729",
  224: "CD_I998103",
  225: "CD_I1011771",
  226: "CD_I997142",
  227: "CD_I1005053",
  228: "CD_I1017110",
  229: "CD_I1004995",
  230: "CD_I1017094",
  231: "CD_I294592",
  232: "CD_I1002312",
  233: "CD_I1009386",
  234: "CD_I993816",
  235: "CD_I260930",
  236: "CD_I1009189",
  237: "CD_I990827",
  238: "CD_I1004863",
  239: "CD_I1000998",
  240: "CD_I1005986",
  241: "CD_I240283",
  242: "CD_I1005054",
  243: "CD_I997100",
  244: "CD_I1000953",
  245: "CD_I1004965",
  246: "CD_I1023270",
  247: "CD_I296588",
  248: "CD_I296041",
  249: "CD_I994539",
  250: "CD_I1000981",
  251: "CD_I293535",
  252: "CD_I992059",
  253: "CD_I298298",
  254: "CD_I998205",
  255: "CD_I297406",
  256: "CD_I296035",
  257: "CD_I280819",
  258: "CD_I293738",
  259: "CD_I1003192",
  260: "CD_I1011583",
  261: "CD_I297523",
  262: "CD_I993905",
  263: "CD_I993979",
  264: "CD_I994077",
  265: "CD_I999391",
  266: "CD_I994386",
  267: "CD_I295518",
  268: "CD_I298312",
  269: "CD_I261224",
  270: "CD_I281007",
  271: "CD_I1013220",
  272: "CD_I281065",
  273: "CD_I1002232",
  274: "CD_I992049",
  275: "CD_I1000963",
  276: "CD_I1006058",
  277: "CD_I291720",
  278: "CD_I294013",
  279: "CD_I293871",
  280: "CD_I296733",
  281: "CD_I270896",
  282: "CD_I291790",
  283: "CD_I998659",
  284: "CD_I290307",
  285: "CD_I1010841",
  286: "CD_I298265",
  287: "CD_I1017088",
  288: "CD_I291313",
  289: "CD_I293193",
  290: "CD_I1001438",
  291: "CD_I296205",
  292: "CD_I990609",
  293: "CD_I298272",
  294: "CD_I993902",
  295: "CD_I250267",
  296: "CD_I1005599",
  297: "CD_I992499",
  298: "CD_I1006136",
  299: "CD_I1002235",
  300: "CD_I1006130",
  300: "CD_I1006130",
  301: "CD_I291800",
  302: "CD_I290085",
  303: "CD_I281085",
  304: "CD_I281091",
  305: "CD_I298437",
  306: "CD_I1002248",
  307: "CD_I1008280",
  308: "CD_I996059",
  309: "CD_I1015889",
  310: "CD_I999316",
  311: "CD_I998130",
  312: "CD_I1012805",
  313: "CD_I1002947",
  314: "CD_I293845",
  315: "CD_I993834",
  316: "CD_I998134",
  317: "CD_I291902",
  318: "CD_I998133",
  319: "CD_I1002404",
  320: "CD_I291978",
  321: "CD_I298211",
  322: "CD_I998145",
  323: "CD_I294168",
  324: "CD_I297899",
  325: "CD_I291867",
  326: "CD_I998102",
  327: "CD_I1009253",
  328: "CD_I298470",
  329: "CD_I292511",
  330: "CD_I1018335",
  331: "CD_I1009421",
  332: "CD_I1013268",
  333: "CD_I1006133",
  334: "CD_I1001026",
  335: "CD_I296347",
  336: "CD_I998114",
  337: "CD_I1011659",
  338: "CD_I1008543",
  339: "CD_I1000223",
  340: "CD_I1013133",
  341: "CD_I297456",
  342: "CD_I998180",
  343: "CD_I294877",
  344: "CD_I295203",
  345: "CD_I1004909",
  346: "CD_I993946",
  347: "CD_I1011936",
  348: "CD_I298445",
  349: "CD_I294504",
  350: "CD_I297354",
  351: "CD_I297473",
  352: "CD_I992468",
  353: "CD_I291509",
  354: "CD_I1006135",
  355: "CD_I1004870",
  356: "CD_I997033",
  357: "CD_I1002245",
  358: "CD_I1013230",
  359: "CD_I293813",
  360: "CD_I996708",
  361: "CD_I1002267",
  362: "CD_I270917",
  363: "CD_I1004912",
  364: "CD_I1006203",
  365: "CD_I1013409",
  366: "CD_I1007881",
  367: "CD_I999326",
  368: "CD_I1000932",
  369: "CD_I1006137",
  370: "CD_I1023518",
  371: "CD_I1005330",
  372: "CD_I293957",
  373: "CD_I993903",
  374: "CD_I1006127",
  375: "CD_I270963",
  376: "CD_I298450",
  377: "CD_I1002220",
  378: "CD_I1000980",
  379: "CD_I296280",
  380: "CD_I280506",
  381: "CD_I998791",
  382: "CD_I294125",
  383: "CD_I296359",
  384: "CD_I1004985",
  385: "CD_I1000960",
  386: "CD_I271045",
  387: "CD_I1006148",
  388: "CD_I1006030",
  389: "CD_I294469",
  390: "CD_I297767",
  391: "CD_I996483",
  392: "CD_I290801",
  393: "CD_I294092",
  394: "CD_I1002922",
  395: "CD_I998260",
  396: "CD_I1002256",
  397: "CD_I1005000",
  398: "CD_I1005107",
  399: "CD_I998782",
  400: "CD_I1008478",
  401: "CD_I999827",
  402: "CD_I998195",
  403: "CD_I1013973",
  404: "CD_I998215",
  405: "CD_I1006114",
  406: "CD_I990882",
  407: "CD_I1006096",
  408: "CD_I1004364",
  409: "CD_I281078",
  410: "CD_I298358",
  411: "CD_I990978",
  412: "CD_I992054",
  413: "CD_I270146",
  414: "CD_I1008230",
  415: "CD_I1008855",
  416: "CD_I292128",
  417: "CD_I291856",
  418: "CD_I1013611",
  419: "CD_I1023517",
  420: "CD_I1002264",
  421: "CD_I1003546",
  422: "CD_I294472",
  423: "CD_I1013974",
  424: "CD_I296355",
  425: "CD_I1003520",
  426: "CD_I1001449",
  427: "CD_I296735",
  428: "CD_I1008139",
  429: "CD_I291849",
  430: "CD_I994599",
  431: "CD_I1009708",
  432: "CD_I990606",
  433: "CD_I291848",
  434: "CD_I1006100",
  435: "CD_I294199",
  436: "CD_I298409",
  437: "CD_I250365",
  438: "CD_I1008454",
  439: "CD_I996464",
  440: "CD_I991773",
  441: "CD_I991939",
  442: "CD_I291819",
  443: "CD_I1004385",
  444: "CD_I291492",
  445: "CD_I1009291",
  446: "CD_I1006087",
  447: "CD_I997206",
  448: "CD_I1005717",
  449: "CD_I997254",
  450: "CD_I291351",
  451: "CD_I1000864",
  452: "CD_I298419",
  453: "CD_I1011954",
  454: "CD_I1007124",
  455: "CD_I1009260",
  456: "CD_I1008083",
  457: "CD_I293854",
  458: "CD_I280737",
  459: "CD_I1004757",
  460: "CD_I993841",
  461: "CD_I1007102",
  462: "CD_I993795",
  463: "CD_I295584",
  464: "CD_I298281",
  465: "CD_I996442",
  466: "CD_I1007238",
  467: "CD_I1001396",
  468: "CD_I280944",
  469: "CD_I993953",
  470: "CD_I1006576",
  471: "CD_I1013532",
  472: "CD_I1006103",
  473: "CD_I1008312",
  474: "CD_I1015827",
  475: "CD_I1006121",
  476: "CD_I1002347",
  477: "CD_I1009241",
  478: "CD_I1004940",
  479: "CD_I1011994",
  480: "CD_I998128",
  481: "CD_I293651",
  482: "CD_I297255",
  483: "CD_I992048",
  484: "CD_I298302",
  485: "CD_I290746",
  486: "CD_I290847",
  487: "CD_I1004819",
  488: "CD_I1008198",
  489: "CD_I250395",
  490: "CD_I990704",
  491: "CD_I1009383",
  492: "CD_I1004894",
  493: "CD_I291753",
  494: "CD_I1018018",
  495: "CD_I993940",
  496: "CD_I294733",
  497: "CD_I993806",
  498: "CD_I295222",
  499: "CD_I1002403",
  500: "CD_I1009228",
  501: "CD_I990816",
  502: "CD_I1014038",
  503: "CD_I1008091",
  504: "CD_I294624",
  505: "CD_I1012860",
  506: "CD_I290675",
  507: "CD_I996743",
  508: "CD_I1009191",
  509: "CD_I1012833",
  510: "CD_I1011755",
  511: "CD_I1009201",
  512: "CD_I280711",
  513: "CD_I1012829",
  514: "CD_I290246",
  515: "CD_I1008882",
  516: "CD_I280804",
  517: "CD_I260257",
  518: "CD_I1003203",
  519: "CD_I1002938",
  520: "CD_I1009385",
  521: "CD_I1013224",
  522: "CD_I291861",
  523: "CD_I1005547",
  524: "CD_I1006013",
  525: "CD_I290527",
  526: "CD_I298174",
  527: "CD_I294654",
  528: "CD_I295467",
  529: "CD_I1008752",
  530: "CD_I1013978",
  531: "CD_I294429",
  532: "CD_I1008436",
  533: "CD_I993797",
  534: "CD_I1012386",
  535: "CD_I993917",
  536: "CD_I1015810",
  537: "CD_I1009399",
  538: "CD_I293884",
  539: "CD_I291975",
  540: "CD_I1008171",
  541: "CD_I992351",
  542: "CD_I993820",
  543: "CD_I1009015",
  544: "CD_I997846",
  545: "CD_I997501",
  546: "CD_I1009186",
  547: "CD_I291570",
  548: "CD_I1004095",
  549: "CD_I1002222",
  550: "CD_I291969",
  551: "CD_I1004034",
  552: "CD_I290160",
  553: "CD_I294068",
  554: "CD_I1006059",
};
