import React from "react";
import Image from "next/image";
import { guessStatus, arrowDir } from "../../lib/utils/constants";

export function GuessCell({
  value,
  status,
  suffix = "",
  logoId,
  failed = false,
}) {
  const [guess, dir] = status;

  const bg = failed
    ? "bg-[#920000]"
    : guess === guessStatus.CORRECT
    ? "bg-[#37be75]"
    : guess === guessStatus.CLOSE
    ? "bg-[#f4e878]"
    : "bg-[#edeae5]";
  const color = failed || guess === guessStatus.CORRECT ? "text-white" : null;
  const baseStyles = `game-table__cell flex items-center justify-center text-center text-xs md:text-base md:leading-none border-solid border-[#f5f2ec] border-r border-t md:border-t-0 bg-fixed py-3.5 px-2 relative last:border-r-0 leading-none`;

  return (
    <div className={`${baseStyles} ${bg} ${color}`}>
      <div>
        {logoId && (
          <div className="team-logo block my-2 mx-auto md:w-12">
            <Image
              src={logoId}
              alt="Team logo"
              layout="intrinsic"
              width={36}
              height={18}
            />
          </div>
        )}
        <div>
          {value ?? "-"}
          {suffix}
        </div>
        {value !== null && dir !== arrowDir.NONE && (
          <div className="absolute left-0 right-0 mt-0.5">{dir}</div>
        )}
      </div>
    </div>
  );
}
