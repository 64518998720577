import { useContext } from "react";
import { trackSelect } from "../../lib/utils/analytics";
import SilhouetteImage from "../silhouette/SilhouetteImage";
import SilhouetteModal from "../silhouette/SilhouetteModal";
import { DataContext } from "../../lib/store/DataProviders";

function SilhouetteButton(params) {
  const stores = useContext(DataContext);
  const [silhouetteOn, saveSilhouetteOn, guesses] = stores.usePersistentStore(
    (state) => [state.silhouetteOn, state.saveSilhouetteOn, state.guesses]
  );
  const [isOpen, setIsOpen] = stores.useGameStore((state) => [
    state.isSilhouetteOpen,
    state.setIsSilhouetteOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackSelect({
      contentType: "button",
      itemId: "show_silhouette",
    });
    silhouetteOn === 0 && saveSilhouetteOn(guesses.length + 1);
  }

  return (
    <>
      <button
        onClick={openModal}
        className="uppercase px-5 mt-1 mb-3.5 py-2 md:px-7 md:py-5 text-worpel-blue underline font-bold"
      >
        Show Silhouette
      </button>

      <SilhouetteModal show={isOpen} onClose={closeModal}>
        <ModalBody />
      </SilhouetteModal>
    </>
  );
}

function ModalBody() {
  return (
    <div className="mt-2 text-worpel-blue">
      <SilhouetteImage />
      <div className="border-y border-solid border-worpel-blue pt-9 pb-8 px-2.5 text-center">
        <h3 className="text-lg md:text-xl">Who is this</h3>
        <h2 className="text-2xl md:text-3xl font-extrabold uppercase my-2.5 tracking-wide leading-none">
          Mystery Player?
        </h2>
      </div>
    </div>
  );
}

export default SilhouetteButton;
