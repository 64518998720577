import * as React from "react";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { enums, MAX_GUESSES } from "../lib/utils/constants";
import { trackLevelEnd, trackLevelStart } from "../lib/utils/analytics";
import { DataContext } from "../lib/store/DataProviders";

const filterOptions = createFilterOptions({
  limit: 5,
});

const theme = createTheme({
  typography: {
    fontFamily: [
      "Inter",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function SearchInput() {
  const stores = React.useContext(DataContext);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [placeholderCopy, setPlaceholderCopy] = React.useState(
    `Guess 1 of ${MAX_GUESSES}`
  );
  const [guesses, saveGuess, history, saveHistory] = stores.usePersistentStore(
    (state) => [
      state.guesses,
      state.saveGuess,
      state.history,
      state.saveHistory,
    ]
  );
  const [
    players,
    target,
    levelNumber,
    levelResult,
    analyticsGameName,
    initError,
    setLevelResult,
    setIsAnswerOpen,
    setIsResultOpen,
  ] = stores.useGameStore((state) => [
    state.players,
    state.target,
    state.levelNumber,
    state.levelResult,
    state.analyticsGameName,
    state.initError,
    state.setLevelResult,
    state.setIsAnswerOpen,
    state.setIsResultOpen,
  ]);

  React.useEffect(() => {
    const sortedPlayers = Object.values(players).sort((a, b) =>
      a.surname.localeCompare(b.surname)
    );
    setOptions(sortedPlayers);
  }, [players]);

  React.useEffect(() => {
    if (
      levelResult === enums.levelResult.WON &&
      history[levelNumber] !== undefined
    ) {
      setPlaceholderCopy(`You solved it in ${history[levelNumber]}!`);
    } else if (levelResult === enums.levelResult.LOST) {
      setPlaceholderCopy(`Game Over`);
    } else {
      setPlaceholderCopy(`Guess ${guesses.length + 1} of ${MAX_GUESSES}`);
    }
  }, [levelNumber, levelResult, history, guesses]);

  const handleOpen = () => {
    inputValue.length > 0 && setOpen(true);
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    newInputValue.length > 0 ? setOpen(true) : setOpen(false);
  };
  const handleSelect = (event, newValue, reason) => {
    setInputValue("");
    if (reason === "selectOption") {
      //console.log(newValue);
      saveGuess({ playerId: newValue.playerId });

      const attempt = guesses.length + 1;

      if (attempt === 1) {
        trackLevelStart({
          gameName: analyticsGameName,
          levelNumber: levelNumber,
          target: target,
        });
      }

      if (newValue === target) {
        saveHistory(attempt);
        setLevelResult(enums.levelResult.WON);
        setIsAnswerOpen(true);
        trackLevelEnd({
          gameName: analyticsGameName,
          levelNumber: levelNumber,
          target: target,
          success: true,
        });
      } else if (attempt === MAX_GUESSES) {
        saveHistory("fail");
        setLevelResult(enums.levelResult.LOST);
        setIsResultOpen(true);
        trackLevelEnd({
          gameName: analyticsGameName,
          levelNumber: levelNumber,
          target: target,
          success: false,
        });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        key={guesses.length}
        id="highlights-demo"
        className="mx-auto font-sans max-w-lg"
        disabled={levelResult !== enums.levelResult.TBD || initError}
        freeSolo
        filterOptions={filterOptions}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        onChange={handleSelect}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={options}
        getOptionLabel={(p) =>
          p.givenName === undefined ? "" : `${p.givenName} ${p.surname}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholderCopy}
            margin="normal"
            sx={{
              input: {
                color: "#005979",
                fontWeight: 600,
                "&::placeholder": {
                  color: "#667070",
                  opacity: 1,
                },
              },
            }}
            style={{
              backgroundColor:
                levelResult === enums.levelResult.TBD ? "white" : "#e9ecef",
            }}
          />
        )}
        renderOption={(props, p, { inputValue }) => {
          const matches = match(`${p.givenName} ${p.surname}`, inputValue, {
            insideWords: true,
          });
          const parts = parse(`${p.givenName} ${p.surname}`, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    </ThemeProvider>
  );
}

export default SearchInput;
