import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useContext } from "react";
import { trackSelect } from "../../lib/utils/analytics";
import { DataContext } from "../../lib/store/DataProviders";
import Image from "next/image";
import logo from "../../../public/img/icon-info.svg";

function HowToPlayButton({ modalTitle, modalBody }) {
  const stores = useContext(DataContext);

  const [instructionsDismissed, saveInstructionsDismissed] =
    stores.usePersistentStore((state) => [
      state.instructionsDismissed,
      state.saveInstructionsDismissed,
    ]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!instructionsDismissed) {
      setIsOpen(true);
    }
  }, [instructionsDismissed]);

  function closeModal() {
    saveInstructionsDismissed(true);
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackSelect({
      contentType: "button",
      itemId: "how_to_play",
    });
  }

  return (
    <>
      <button
        onClick={openModal}
        className="uppercase w-10 md:w-auto pt-1 md:px-7 md:py-3 text-white hover:text-stone-200 font-bold text-xs md:text-sm"
      >
        <span className="hidden md:inline-block">How to Play</span>
        <span className="md:hidden">
          <Image
            src={logo}
            alt="Worpel logo"
            layout="intrinsic"
            className="inline-block"
            width={18}
            height={18}
          />
        </span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-2.5 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-[calc(var(--vh,1vh)*100)] align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500 delay-100"
              enterFrom="opacity-0 scale-100 mb-20"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100 mb-20"
            >
              <div className="inline-block w-full max-w-md p-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold text-center leading-6 text-worpel-blue"
                >
                  {modalTitle}
                  <ModalCloseButton clickCallback={closeModal} />
                </Dialog.Title>
                {modalBody}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li className="my-4">
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} p-0.5 -m-0.5 rounded-sm inline-block`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="float-right inline-flex justify-center -mt-2 px-2 py-2 text-2xl font-medium leading-none"
      onClick={clickCallback}
    >
      ×
    </button>
  );
}

export default HowToPlayButton;
