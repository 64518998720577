import { guessStatus, arrowDir, PRECISION } from "../utils/constants";
import { getAge } from "../utils/common";
import teamMap from "./teamMap";
import borderStates from "./borderStates";
import posMap from "./posMap";

export function compareTeam(target, guess) {
  const t = target.team;
  const g = guess.team;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const status =
    teamMap[t].colors.filter((c) => teamMap[g].colors.includes(c)).length > 0
      ? guessStatus.CLOSE
      : guessStatus.INCORRECT;

  return [status, arrowDir.NONE];
}

export function compareState(target, guess) {
  const t = teamMap[target.team].state;
  const g = teamMap[guess.team].state;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const status = borderStates[t].includes(g)
    ? guessStatus.CLOSE
    : guessStatus.INCORRECT;

  return [status, arrowDir.NONE];
}

export function comparePos(target, guess) {
  const t = target.position;
  const g = guess.position;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const status = posMap[t].close.includes(g)
    ? guessStatus.CLOSE
    : guessStatus.INCORRECT;

  return [status, arrowDir.NONE];
}

export function compareAge(target, guess) {
  const t = getAge(target.dateOfBirth);
  const g = getAge(guess.dateOfBirth);

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const dir = t < g ? arrowDir.DOWN : arrowDir.UP;
  const status =
    Math.abs(t - g) <= PRECISION ? guessStatus.CLOSE : guessStatus.INCORRECT;

  return [status, dir];
}

export function compareDebut(target, guess) {
  const t = target.debutYear;
  const g = guess.debutYear;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const dir = t < g ? arrowDir.LEFT : arrowDir.RIGHT;
  const status =
    Math.abs(t - g) <= PRECISION ? guessStatus.CLOSE : guessStatus.INCORRECT;

  return [status, dir];
}

export function compareLastSeason(target, guess) {
  const t = target.lastSeason;
  const g = guess.lastSeason;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const dir = t < g ? arrowDir.LEFT : arrowDir.RIGHT;
  const status =
    Math.abs(t - g) <= PRECISION ? guessStatus.CLOSE : guessStatus.INCORRECT;

  return [status, dir];
}

export function compareHeight(target, guess) {
  const t = target.heightCm;
  const g = guess.heightCm;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const dir = t < g ? arrowDir.DOWN : arrowDir.UP;
  const status =
    Math.abs(t - g) <= PRECISION ? guessStatus.CLOSE : guessStatus.INCORRECT;

  return [status, dir];
}

export function compareNumber(target, guess) {
  const t = target.jumperNumber;
  const g = guess.jumperNumber;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const dir = t < g ? arrowDir.DOWN : arrowDir.UP;
  const status =
    Math.abs(t - g) <= PRECISION ? guessStatus.CLOSE : guessStatus.INCORRECT;

  return [status, dir];
}

export function comparePremierships(target, guess) {
  const t = target.premierships.length;
  const g = guess.premierships.length;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const dir = t < g ? arrowDir.DOWN : arrowDir.UP;
  const status =
    Math.abs(t - g) <= PRECISION ? guessStatus.CLOSE : guessStatus.INCORRECT;

  return [status, dir];
}

export function compareNumTeams(target, guess) {
  const t = target.teams.length;
  const g = guess.teams.length;

  if (t === g) return [guessStatus.CORRECT, arrowDir.NONE];

  const dir = t < g ? arrowDir.DOWN : arrowDir.UP;
  const status =
    Math.abs(t - g) <= PRECISION ? guessStatus.CLOSE : guessStatus.INCORRECT;

  return [status, dir];
}
