const borderStates = {
  ACT: ["NSW"],
  NT: ["WA", "SA", "QLD"],
  NSW: ["ACT", "QLD", "VIC", "SA"],
  VIC: ["NSW", "SA"],
  SA: ["WA", "QLD", "VIC", "NSW", "NT"],
  WA: ["NT", "SA"],
  TAS: [],
  INT: [],
  QLD: ["NT", "SA", "NSW"],
};

export default borderStates;
