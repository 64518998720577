import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useContext } from "react";
import Image from "next/image";
import { trackSelect } from "../../lib/utils/analytics";
import { DataContext } from "../../lib/store/DataProviders";
import logo from "../../../public/img/icon-stats.svg";

function StatsButton(params) {
  const stores = useContext(DataContext);
  const [resultsByLevelNumber] = stores.usePersistentStore((state) => [
    state.history,
  ]);
  const levelNumber = stores.useGameStore((state) => state.levelNumber);
  const levelResults = Object.values(resultsByLevelNumber);
  const [isOpen, setIsOpen] = useState(false);
  const [stats, setStats] = useState({ won: 0 });

  useEffect(() => {
    setStats(getStats());
  }, [levelResults.length]);

  function getStats() {
    const levelNumbersPlayed = Object.keys(resultsByLevelNumber).sort(
      (a, b) => a - b
    );
    const levelNumbersPlayedReversed = Object.keys(resultsByLevelNumber).sort(
      (a, b) => b - a
    );
    const levelResults = Object.values(resultsByLevelNumber);
    const resultsCounts = [1, 2, 3, 4, 5, 6, 7, 8].map((elem) => ({
      result: elem,
      count: levelResults.reduce(
        (count, guess) => (guess === elem ? count + 1 : count),
        0
      ),
    }));
    const failsCount = levelResults.reduce(
      (count, guess) => (guess === "fail" ? count + 1 : count),
      0
    );

    const winsCount = levelNumbersPlayed.length - failsCount;
    const totalGuessesInWonMatches = levelResults.reduce(
      (count, guess) => (guess !== "fail" ? count + guess : count),
      0
    );

    const calcCurrentStreak = () => {
      const startGame =
        parseInt(levelNumbersPlayedReversed[0]) === levelNumber
          ? levelNumber
          : levelNumber - 1;

      let streak = 0;
      levelNumbersPlayedReversed.forEach((el, i) => {
        const isStreakAlive = streak === i;
        const dayPlayed = parseInt(el);
        const result = resultsByLevelNumber[el];
        if (isStreakAlive && dayPlayed === startGame - i && result !== "fail") {
          streak++;
        }
      });
      return streak;
    };

    const calcBestStreak = () => {
      const firstGameResult = levelResults[0];
      let currStreak = firstGameResult === "fail" ? 0 : 1;
      let bestStreak = currStreak;

      for (let i = 1; i < levelNumbersPlayed.length; i++) {
        const currentDayPlayed = parseInt(levelNumbersPlayed[i]);
        const currentDayResult = resultsByLevelNumber[currentDayPlayed];
        const lastDayPlayed = parseInt(levelNumbersPlayed[i - 1]);
        if (
          currentDayPlayed === lastDayPlayed + 1 &&
          currentDayResult !== "fail"
        ) {
          currStreak++;
          bestStreak = Math.max(currStreak, bestStreak);
        } else if (currentDayResult !== "fail") {
          currStreak = currentDayResult !== "fail" ? 1 : 0;
        }
      }

      return bestStreak;
    };

    if (winsCount === 0) {
      return {
        firstPlayed: null,
        played: levelResults.length,
        streak: 0,
        bestStreak: 0,
        won: winsCount,
        totalGuessesInWonMatches: totalGuessesInWonMatches,
        avgGuessesInWonMatches: (totalGuessesInWonMatches / winsCount).toFixed(
          1
        ),
        histogram: [...resultsCounts, { result: "X", count: failsCount }],
      };
    }

    const stats = {
      firstPlayed: levelNumbersPlayed[0],
      played: levelResults.length,
      streak: calcCurrentStreak(),
      bestStreak: calcBestStreak(),
      won: winsCount,
      totalGuessesInWonMatches: totalGuessesInWonMatches,
      avgGuessesInWonMatches: (totalGuessesInWonMatches / winsCount).toFixed(1),
      histogram: [...resultsCounts, { result: "X", count: failsCount }],
    };

    return stats;
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackSelect({
      contentType: "button",
      itemId: "my_stats",
    });
  }

  return (
    <>
      <button
        onClick={openModal}
        className="uppercase w-10 md:w-auto pt-1 md:px-7 md:py-3 text-white hover:text-stone-200 font-bold text-xs md:text-sm"
      >
        <span className="hidden md:inline-block">My Stats</span>
        <span className="md:hidden">
          <Image
            src={logo}
            alt="Worpel logo"
            layout="intrinsic"
            className=" inline-block"
            width={18}
            height={18}
          />
        </span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-2.5 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-[calc(var(--vh,1vh)*100)] align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500 delay-100"
              enterFrom="opacity-0 scale-100 mb-[80px]"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100 mb-[80px]"
            >
              <div className="inline-block w-full max-w-md py-4 px-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold text-center leading-6 text-worpel-blue pl-7"
                >
                  My Statistics
                  <ModalCloseButton clickCallback={closeModal} />
                </Dialog.Title>
                <ModalBody stats={stats} history={resultsByLevelNumber} />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function ModalBody({ stats }) {
  const { firstPlayed, played, won, streak, bestStreak, histogram } = stats;

  const winPct = played === 0 ? 0 : Math.round((won / played) * 100);
  const mode = Math.max(...histogram.map((o) => o.count));

  return (
    <div className="text-center text-worpel-blue">
      <div className="border-solid border-worpel-blue md:py-2 px-1">
        <div className="flex flex-row justify-evenly my-6">
          <StatBox title="Played" value={played} />
          <StatBox title={"Win %"} value={`${winPct}`} />
          <StatBox title="Current Streak" value={streak} />
          <StatBox title="Best Streak" value={bestStreak} />
        </div>
        <div className="pt-3 pb-1.5 px-4">
          <h3 className="md:text-xl uppercase mb-2">Guess Distribution</h3>
          {histogram.map(({ result, count }) => (
            <ChartBar
              key={result}
              label={result}
              value={count}
              total={played}
              mode={mode}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function StatBox({ title, value }) {
  return (
    <div className="flex flex-col w-1/3 py-2 md:pt-3 mx-1 rounded-md bg-slate-200">
      <div className="text-xs md:text-sm font-normal opacity-70 mb-1 leading-tight px-4 h-8 flex flex-col justify-center">
        {title}
      </div>
      <div className="text-2.5xl md:text-3xl">{value}</div>
    </div>
  );
}

function ChartBar({ label, value, total, mode }) {
  const highlight = value === mode;
  const pct = total == 0 ? 0 : Math.round((value / total) * 100);
  const widthPct = Math.round((value / mode) * 100) * 0.75;
  const barColor = highlight ? "bg-worpel-blue" : "bg-slate-300";
  return (
    <div className="flex flex-row justify-start items-center">
      <div className="inline-block w-5 text-sm">{label}</div>
      <div className={`inline-block h-5 w-2 ${barColor} my-1`}></div>
      <div
        className={`inline-block h-5 ${barColor} my-1 -ml-0.5 rounded-r-md`}
        style={{ width: `${widthPct}%` }}
      ></div>
      <div className="inline-block text-left w-8 text-xs opacity-70 ml-1.5">
        {pct}%
      </div>
    </div>
  );
}

function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="float-right inline-flex justify-center -mt-2 px-2 py-2 text-2xl font-medium leading-none"
      onClick={clickCallback}
    >
      ×
    </button>
  );
}

export default StatsButton;
