import { useContext } from "react";
import { DataContext } from "../../lib/store/DataProviders";
import { enums } from "../../lib/utils/constants";
import AnswerButton from "./AnswerButton";
import ResultButton from "./ResultButton";
import SilhouetteButton from "./SilhouetteButton";

function ButtonSwitcher(props) {
  const stores = useContext(DataContext);
  const levelResult = stores.useGameStore((state) => state.levelResult);

  return levelResult === enums.levelResult.WON ? (
    <AnswerButton footer={props.footer} />
  ) : levelResult === enums.levelResult.LOST ? (
    <ResultButton footer={props.footer} />
  ) : (
    <SilhouetteButton />
  );
}

export default ButtonSwitcher;
