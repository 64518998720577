export function NetworkError({ onRetry }) {
  return (
    <div className="text-center text-sm font-normal text-worpel-blue max-w-sm mx-auto mt-4 p-4">
      <p className="mb-3 font-semibold text-lg">Network Error</p>
      <p className="mb-5">
        Couldn't load today's player. Please refresh or try again later.
      </p>
      <button
        onClick={onRetry}
        className="bg-worpel-blue py-2.5 px-5 rounded relative text-sm text-white font-semibold uppercase"
      >
        Try Again
      </button>
    </div>
  );
}
