import { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { DataContext } from "../lib/store/DataProviders";
import { handleShare } from "../lib/utils/share";

function ShareButton() {
  const stores = useContext(DataContext);
  const [isCopiedShown, setIsCopiedShown] = useState(false);

  const handleClick = () => {
    handleShare({ stores });

    if (!isMobile) {
      setIsCopiedShown(true);
      setTimeout(() => {
        setIsCopiedShown(false);
      }, 3000);
    }
  };

  return (
    <button
      className="bg-worpel-blue py-3 px-9 rounded relative text-sm text-white font-bold uppercase"
      onClick={handleClick}
    >
      Share My Score
      <span
        className={`transition-opacity duration-500 ${
          isCopiedShown ? "opacity-100" : "opacity-0"
        } absolute top-full left-1/2 -translate-x-1/2 translate-y-1.5 text-worpel-blue text-xs`}
      >
        COPIED!
      </span>
    </button>
  );
}

export default ShareButton;
