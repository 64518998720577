export function PlayLegendsFooter() {
  return (
    <a
      href="/legends"
      className="pt-2 pb-3 -mb-4 px-4 sm:px-6 -mx-4 sm:-mx-6  flex justify-start items-center text-left"
    >
      <div
        className="inner bg-contain bg-no-repeat false bg-center h-16 md:h-20 w-24 md:w-40 mr-3 ml-1"
        style={{
          backgroundImage: `url("/img/legends/legends-promo-silhouette.png")`,
        }}
      ></div>
      <div className="mr-auto">
        <div className="text-sm md:text-lg font-extrabold">
          Play Worpel Legends
        </div>
        <div className="text-xs md:text-sm">Can you guess today's legend?</div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        aria-hidden="true"
        className="h-6 w-6 ml-2 cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 5l7 7-7 7"
        ></path>
      </svg>
    </a>
  );
}
